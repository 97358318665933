import React from 'react'
import { backgrounds } from '@Common/Resources/Backgrounds'
import { getHost } from '@Common/Services'
const Background = ({ children, customBackgroundUrl }) => {
  const theme = getHost()[0].theme || 1
  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    backgroundImage: `url(${customBackgroundUrl === undefined ? backgrounds[theme] : (backgrounds[customBackgroundUrl] || backgrounds[0])})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'noRepeat'
  }

  return <div style={backgroundStyle}>{children}</div>
}

export { Background }
