import White from './White.png'
import Theme1 from './Theme1.jpg'
import Theme2 from './Theme2.jpg'
import Theme3 from './Theme3.jpg'
import Theme4 from './Theme4.jpg'
import Theme5 from './Theme5.jpg'
import Theme6 from './Theme6.png'
import Theme7 from './Theme7.png'
import Gattinoni1 from './Gattinoni1.jpg'
import Gattinoni from './GattinoniBT.jpg'
import Catalopulos from './Catalopulos.jpeg'
import Transparent from './Transparent.png'
import TexBackground from './BackgroundTheme1_TEX.png'
import SfondoSiap from './SfondoSiap.png'
import Robintur from './Robintur.jpg'

export const backgrounds = [
  White,
  Theme1,
  Theme2,
  Theme3,
  Theme4,
  Theme5,
  Theme6,
  Theme7,
  Gattinoni1,
  Gattinoni,
  Catalopulos,
  Transparent,
  TexBackground,
  SfondoSiap,
  Robintur

]
